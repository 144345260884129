// import { marked } from 'marked'
// import hljs from 'highlight.js'
const md = require('markdown-it')();

export default {
  name: 'event-stream',

  data() {
    return {
      decodedString: "<code>console.log('Hello, World!');</code>",
      // 流式数据
      streamData: '#### 我是智能api,请输入你想问的任何问题\n' + '\n' + '\n' + '\n' + '我能做很多，例如：\n' + '\n' + '- 智能回答问题\n' + '- 智能写文档\n' + '- 智能写代码\n' + '- and more\n' + '\n',
      streamDataSSE: '',
      text: '',
      sse_text: '',
      loading: false,
      loadingSSE: false,
      error: null,
      sse_error: null,
      loadingMessage: '不要着急，正在处理中...',
      loadingMessageSSE: 'sse接口正在请求中'
    };
  },

  watch: {
    streamDataSSE() {
      try {
        const streamDataSSE = JSON.parse(this.streamDataSSE);
        this.streamDataSSE = streamDataSSE && streamDataSSE.message.content.parts[0];
      } catch (err) {}
    }

  },
  computed: {
    compiledMarkdown() {
      // return this.streamDataSSE
      return md.render(this.streamDataSSE); // return marked(this.streamDataSSE, {
      //   highlight: function(markdown) {
      //     return hljs.highlightAuto(markdown).value
      //   }
      // })
    },

    decodedStringMarkdown() {
      return this.decodedString;
    }

  },
  methods: {
    async getStreamData(text) {
      try {
        this.loading = true;
        const response = await fetch(`https://www.ipolarx.com/api/jf-tech/v1/chat?message=${text}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream'
          } // body: JSON.stringify({
          //   prompt: text,
          //   message_id: 'd10cf3ef-d483-4a11-8d6e-332f81f79b56',
          //   parent_message_id: '0303da2c-7af6-483f-aa0c-609b0befa9eb',
          //   model: 'text-davinci-002-render-sha',
          //   timezone_offset_min: -480
          // })

        });
        this.streamDataSSE = '';
        const reader = response.body.getReader();

        const readStream = async () => {
          this.loading = false;

          while (true) {
            const {
              done,
              value
            } = await reader.read();

            if (done) {
              console.log('数据流下载完毕');
              return;
            }

            const decoder = new TextDecoder();
            const decodedString = decoder.decode(value); // const str = decodedString
            //   .replace(/^data: \s*/g, '') // 使用全局替换（添加"g"标志）
            //   .replace(/\n/g, '')

            if (decodedString === '[DONE]') {
              console.log('数据流下载完毕');
              return;
            }

            console.log(111, decodedString);
            const streamDataSSE = this.streamDataSSE + decodedString;

            if (streamDataSSE !== '[DONE]') {
              this.streamDataSSE = streamDataSSE;
            }
          }
        };

        await readStream.call(this); // 使用call方法确保箭头函数中的this引用正确的Vue组件实例
      } catch (error) {
        console.log(error);
      }
    }

  }
};