import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-20520e7f"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "stream"
};
const _hoisted_2 = ["innerHTML"];
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");

  const _component_el_button = _resolveComponent("el-button");

  const _component_el_card = _resolveComponent("el-card");

  const _directive_loading = _resolveDirective("loading");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_input, {
    modelValue: $data.text,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.text = $event),
    style: {
      "width": "500px"
    },
    clearable: "",
    onKeyup: _cache[1] || (_cache[1] = _withKeys($event => $options.getStreamData($data.text), ["enter"]))
  }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
    type: "primary",
    onClick: _cache[2] || (_cache[2] = $event => $options.getStreamData($data.text))
  }, {
    default: _withCtx(() => [_createTextVNode("发送")]),
    _: 1
  }), _withDirectives((_openBlock(), _createBlock(_component_el_card, {
    class: "redata",
    "element-loading-spinner": "el-icon-loading",
    "element-loading-text": $data.loadingMessage
  }, {
    default: _withCtx(() => [$options.compiledMarkdown ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      innerHTML: $options.compiledMarkdown
    }, null, 8, _hoisted_2)) : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.error ? "" : "我是智能api,请输入你想问的任何问题"), 1)), _createTextVNode(" " + _toDisplayString($data.error), 1)]),
    _: 1
  }, 8, ["element-loading-text"])), [[_directive_loading, $data.loading]])])]);
}